.MuiBreadcrumbs-li{
    margin-top: 4px;
}

.breadcrumb_text{
    color:  #FFF;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.5px; 
    opacity: 0.50;
    width: 100%;
}