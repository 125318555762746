.NavigationBar_main {
    padding: 0px;
}

.deliveries_container {
    width: 100%;
    padding: 0px 20px 30px;
    overflow-y: scroll;
    height: 100vh;
}

.cursor-pointer {
    cursor: pointer;
}

.delivery {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin: 10px 0px;
}

.delivery-title{
    overflow-wrap: anywhere;
}

.page-title{
    font-family: Poppins;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    overflow-x: auto;
}

.page-header{
    width: 100%;
    position: fixed;
    background-color: rgb(25, 26, 31);
    z-index: 1000;
    padding-top: 30px;
    box-sizing: border-box;
}

.NavigationBar_main{
    padding: 0px;
}

.pe-none{
    pointer-events: none;
}
.approvalBtn{
    cursor: pointer;
    background-color: rgba(128, 128, 128,0.5);
    border-radius: 5px;
    transition: 0.5s;
    padding: 5px;
    margin: 3px;
    font-size: 13px;
}

.approvalBtn:hover{
    background-color: rgba(128, 128, 128);
}
.feedBackFlex{
    display: flex;
}
.expandHover{
    padding-top:3px ;
    padding-bottom: 3px;
}
.expandHover:hover{
    background: rgb(47, 47, 47);
}
.comment-box{
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);;
}
.pt-2{
    padding-top: 10px !important;
}
.recent-delivery-title{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size:12px;
    line-height: 21px;
    color: #fff;
}
.recents{
    overflow-y: auto;
    height: 50%;
}
.recent-btn{
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: 0.5s ease;
    cursor: pointer;
    border-radius: 6px;

}
.recent-btn:hover{
    background: rgba(65,65,65,.35);
}
