.keys-container {
    display: inline-flex;
    padding: 4px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.key-button {
    display: flex;
    padding: 5px 8px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}

.key-button span {
    color: #FFF;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.71px;

}

.key-icon {
    width: 18px;
    height: 18px;
}

.key-button svg,
.key-button img {
    width: 18px;
    height: 18px;
}

.uni {
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.71px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
@media only screen and (max-width:700px) {
    .keys-container {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }
    .keys-container::-webkit-scrollbar{
        width: 0;
        height: 0;
    }

    
}