.activity-date {
    display: flex;
    padding: 8px 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: linear-gradient(to right, #1D2025 0%, #1D2025 85%, #1D2025 100%);

}

.activity-date span {
    color: #fff;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* 192.308% */
    letter-spacing: -0.144px;
    text-transform: uppercase;
}

.main-activity-conatiner {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: #30343C;
    transition: 0.5s;
    cursor: pointer;
}
.w-bg-black{
   background: transparent;
}
.w-bg-regular{
    background: #30343C;

}

.activity-info {
    display: flex;
    padding-bottom: 16px;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;
}

.w-activity-title {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.activity-meta-data {
    display: flex;
    padding-top: 2.5px;
    align-items: flex-start;
    align-self: stretch;
    color: rgba(255, 255, 255, 0.60);

    /* redwing.pages.dev/Poppins/Regular 10 */
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.29px;
    /* 142.9% */
}

.meta-seperator {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    align-items: flex-start;
}

.activity-info-main {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    justify-content: space-between;
}

.activity-notification-btn {
    display: flex;
    padding-left: 10px;
    align-self: stretch;
    gap: 8px;
}

.activity-main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3px;
    align-self: stretch;
    width: 77%;
    overflow-x: auto;
    width: 100%;
}
.main-activity-conatiner:hover{
    background: rgba(65, 65, 65, 0.35);

}
.activity-user-data{
    display: flex;
    gap: 10px;
}
@media screen and (max-width:750px) {
    .main-activity-conatiner {
        padding-left:10px ;
        padding-right: 10px;
    }
    .activity-info-main{
        gap: 5px;
    }
    .activity-meta-data span{ 
        font-size: 8px;

    }
    .w-activity-title {
        font-size: 12px;
        line-height: 15px;
    }
    .activity-main-container{
        font-size: 12px;
        width: 100%;
    }
    
}