.title
{
    /* font-weight: bold; */
    font-size: 18px;
    outline: 0;
    border: 0;
    color: white;
    background-color: transparent;
}
input:focus{
    outline: none;
}
.SubtaskInput
{
    margin-left: 24px;
}