.av-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    box-sizing: content-box;
}

.av-icon-container {
    position: absolute;
    bottom: 25px;
    left: 26px;
    display: flex;
    height: 18px;
    width: 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

}

.border-blue {
    border: 3px solid #436ACD;
}

.border-yellow {
    border: 3px solid #F90;
}

.border-red {
    border: 3px solid #F00;
}

.border-white {
    border: 3px solid #fff;
}

.border-green {
    border: 3px solid #5FB924;
}