.main {
	display: grid;
	grid-template-columns: 0.17fr 1fr;
	width: 100%;
	min-height: 100vh;
	color: white;
}

.leftSidebar {
	border-right: 0.75px solid #565656;
	padding: 30px 20px;
	overflow-y: scroll;
	height: 100vh;
	width: 100%;
}

.body {
	padding: 30px 20px;
	overflow-y: scroll;
	height: 100vh;
	width: 100%;
}

/* width */
.body::-webkit-scrollbar,
.leftSidebar::-webkit-scrollbar {
	width: 2px;
}

/* Track */
.body::-webkit-scrollbar-track,
.leftSidebar::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
.body::-webkit-scrollbar-thumb,
.leftSidebar::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.body::-webkit-scrollbar-thumb:hover,
.leftSidebar::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.backdrop {
    z-index: 999;
    color: #fff;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
}

.heading {
	color: #fff;
	margin-bottom: 15px;
}

.verticalLinesContainer {
	height: calc(100% - 18px);
	padding: 5px 0;
	cursor: pointer;
}

.verticalLines {
	width: 1px;
	background-color: rgba(255, 255, 255, 0.2);
	height: 100%;
	cursor: pointer;
}

.verticalLines:hover {
	background-color: rgba(255, 255, 255, 0.5);
}

.listingsHeading span {
	color: #a78bfa;
}
