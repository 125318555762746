.NavigationBar_main {
    padding: 0px;
}

.comment{
    width: 80%;
    max-width: 800px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: white;
}