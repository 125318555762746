.meta-data-container {
    display: flex;
    gap: 8px;
}

.meta-data-container img {
    width: 19px;
    height: 19px;
}

.data-name {
    color: #999;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.data-value {
    color: #CCC;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}