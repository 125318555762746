.dashboard-header {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 32px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #181A1E;

}

.h-title h1,
.sheet-title {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* 138.889% */

}

.header-tabs {
    display: flex;
    justify-content: space-between;
}

.header-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.header-left-contents {
    display: flex;
    gap: 24px;

}

.header-tabs span {
    padding-bottom: 16px;
    opacity: 0.5;
    cursor: pointer;
    font-size: 13px;
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 161.538% */



}

.header-tabs span.tab__icon {
    display: inline-flex;
    gap: 0.8rem;
    align-items: center;
}

.tab-active {
    color: #fff;
    opacity: 1 !important;
    border-bottom: 2px solid #fff;
    transition: opacity 0.3s, border-bottom 0.3s;
}

.active-labels span {
    opacity: 1;
}

.notification-space {
    margin-bottom: 0px;

}

.worksheet-space {
    margin-bottom: 32px;
}

@media only screen and (max-width:700px) {
    .dashboard-header {
        width: 100%;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
        top: 0;
        margin-bottom: 20px;
        padding: 20px;
        padding-bottom: 0;
    }

    .header-tabs {
        overflow-x: auto;
    }



}

@media only screen and (max-width:470px) {}