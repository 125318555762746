.search_container {
	width: 50%;
	margin: 0px auto;

	background-color: #30343C;
	border-radius: 0.6em;
}

.search_box_container {
	border-bottom: 1px solid rgba(255, 255, 255, 0.144);
	width: 100%;
	padding-block: 1.6rem;
	padding-inline: 3.3rem;
	align-items: center;
}

.search_box {

	background-color: transparent;

	color: white;

}

.result_item:hover,
.selected_result {
	background-color: rgba(255, 255, 255, 0.3);
	cursor: pointer;
}

.results-container {
	padding: 3.3rem;
}

.search-results {
	overflow-y: scroll;
	/* max-height: calc(90vh - 160px); */
	font-size: 12px;
	line-height: 14px;


	height: 70vh;
}

.text_header {
	font-size: 1.1rem !important;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.678);
	font-weight: 700;
	display: flex;
	gap: 1rem;
	align-items: center;
}

.text_header .divider_line {
	flex-grow: 1;
	height: 1px;
	background-color: white;
	opacity: 0.2;
}

.result_text {
	font-family: Poppins;
	font-size: 1.3rem;
	flex-grow: 1;
	line-height: 1.8rem;
	margin: unset;

}

.result_item {
	/* overflow: hidden; */
	width: 100%;
	gap: 12px;

}

.pageStyle {
	width: 100% !important;
	margin: 0 auto 0px !important;
}

.remove-query {
	background: transparent;
	width: fit-content;
	height: fit-content;
	font-size: 16px;
	color: #fff;
	opacity: 0.5;
	border: none;
	cursor: pointer;
}

.remove-query svg {
	display: block;
}

.search-input {
	border: none;
	flex-grow: 1;
	height: 100%;
	font-weight: 500;
	font-family: inherit;

}

.search-input::placeholder {
	color: white;
	opacity: 0.5;
}

@media screen and (max-width: 600px) {
	.search_container {
		width: 100%;
		margin: 0 auto 0px;
	}
}