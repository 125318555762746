.count-container {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    height: 20px;
    width: 20px;
    background: #242E42;
    font-family: Poppins;
   
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    padding: 10px;
    Frame 113 */

    /* Auto layout */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    background: #242E42;
    border-radius: 5px;
    font-weight: 700;




}

.tab-switch {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
    gap: 10px;
    width: Hug (96px)px;
    height: Hug (36px)px;
    padding: 8px 0px 8px 0px;
    gap: 8px;
    border: 0px 0px 1px 0px;
    opacity: 0.5;
    cursor: pointer;

}

.active-tab {
    opacity: 1;
    border-bottom: 1px solid #fff;
}

.segment-divider {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
    height: 36px;
    color: #fff;
    opacity: 0.6;
    display: flex;
    align-items: center;
    padding: 8px 14px;
    gap: 12px;
}

.segment-divider span {
    white-space: nowrap;
    margin-right: 12px;
}

.segment-line {
    height: 1px;
    background: #fff;
    opacity: 0.2;
    flex-grow: 1;
}


.action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px 8px 24px;
}

.action-label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.action-container span {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;

}

.worksheet-label {
    width: 100%;
    display: flex;
    padding: 8px 16px 8px 24px;
    gap: 0px;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;



}

.worksheet-name-label {
    width: 75%;
    word-wrap: break-word;
}

.worksheet-label span {
    white-space: wrap;
    display: block;
}


.author-container {
    display: flex;
    width: 100%;
    padding: 8px 16px 8px 16px;
    gap: 0px;
    justify-content: space-between;
    align-items: center;


}

.author-container span {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    width: 100%;

}

.user-label {
    display: flex;
    gap: 16px;
    align-items: center;
}