.custom_message {
    align-items: center;
    transition: all 200ms ease-in;
    background: transparent;
    position: relative;
    z-index: 10;
}

.custom_message::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 300ms ease-in;
    background: linear-gradient(90deg, rgba(0 0 0 /0.25), transparent);
    border-radius: 0.8rem;
    z-index: -2;
}

.custom_message:hover::before {
    opacity: 1;
}

.custom_message:hover {}

.custom_message>div {
    padding-left: 0;
    transition: all 100ms ease-out;
}

.custom_message:hover>div {
    padding-left: 1.6rem;
}

.pre_btn__container {
    display: flex;
    height: fit-content;
    gap: 1rem;
    align-items: center;

}

.pre__btn {
    all: unset;
    padding: 6px 16px;
    cursor: pointer;
    color: white;
    font-weight: 400;
    font-size: 1.1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    outline: none;
    outline-color: rgba(95, 185, 36, 0.25);
    transition: all 300ms ease-in;

}

.custom_message:hover .paste_pre__btn {

    opacity: 1;
}

.paste_pre__btn {

    opacity: 0;
    transition: opacity 300ms ease-in;
}

.custom_message:hover .send_pre__btn {
    outline: 3px solid rgba(95, 185, 36, 0.25);

    background-color: rgba(95, 185, 36, 1);
}