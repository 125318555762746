.gpt-response-styling pre {
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
    background: #383737;
    padding: 4px 6px;
    border-radius: 3px;
    display: block;
    color: #fff;
    margin-top: 10px;
    border-radius: 6px;
    overflow: auto;


}

.gpt-response-styling h2 {
    color: #fff;
}
.gpt-response-styling ul{
    list-style: disc;
}

.gpt-response-styling ul li pre ,.gpt-response-styling ol li pre{
    width: auto;  
    margin: 0px; 
    display: inline; 
    white-space: pre-wrap; 
    margin: 2px;
}
