.segment-element {
    background-color: rgb(43, 53, 46);
    border-radius: 16px;
    padding: 1vh 1vw;
    color: white;
    cursor: pointer;
    align-self: flex-start;
    margin: 10px 5px;
    position: relative;
    min-height: 50px;
}

.hidden-data {
    opacity: 0;
    visibility: hidden;
}

.visible-data{
    opacity: 1;
    visibility: visible;
    transition: all 0.25s linear;
    transition-delay: 0.25s;
}

.card-title {
    width: 90%;
    font-size: 14px;
    position: absolute;
    left: 5%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: top 0.25s ease;
}

.card-title-top {
    width: 90%;
    font-size: 14px;
    top: 5vh;
    left: 5%;
    text-align: center;
    transition: top 0.25s ease;
}

.wrapper {
  padding-bottom: 8vh;
}



.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    /* padding-left: 30px; */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    /* margin-bottom: 30px; */
  }


  .good-card {
    background-color: #63d471;
    background-image: linear-gradient(315deg, #0c7618 0%, #233329 74%);   
  }

  .neutral-card {
    background-color: #772f1a;
    background-image: linear-gradient(315deg, #772f1a 0%, #f2a65a 74%);    
  }

  .poor-card {
    background-color: #3f0d12;
    background-image: linear-gradient(315deg, #3f0d12 0%, #841627 74%);    
  }

  .yellow-card {
    background-color: #f39f86;
    background-image: linear-gradient(315deg, #ff835d 0%, #b78f18 74%);
  }

  .open-user {
    color: white;
    text-decoration: none;
  }

  .hide-user {
    color: red;
  }