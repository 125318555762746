.page-title {
	color: #fff;
	margin-bottom: 15px;
	font-family: Poppins;
	font-size: 18px;
	line-height: 25px;
	font-weight: normal;
	overflow-x: auto;
}

.text-normal {
	background: transparent;
	color: white !important;
	border: 0;
	font-family: Poppins;
	width: 100%;
	opacity: 1;
	margin-bottom: 10px;
}

.text-muted {
	color: rgba(255, 255, 255, 0.6);
}

.text-small {
	background: transparent;
	color: white;
	border: 0;
	font-family: Poppins;
	font-size: 10px;
	width: 100%;
	opacity: 1;
	margin-bottom: 10px;
}

.main-content {
	padding: 30px 20px;
	overflow-y: scroll;
	height: 100vh;
	width: 100%;
}

/* width */
.main-content::-webkit-scrollbar,
.main-content::-moz-scrollbar {
	width: 2px;
}

/* Track */
.main-content::-webkit-scrollbar-track,
.main-content::-moz-scrollbar-track {
	background: transparent;
}

/* Handle */
.main-content::-webkit-scrollbar-thumb,
.main-content::-moz-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.main-content::-webkit-scrollbar-thumb:hover,
.main-content::-moz-scrollbar-thumb:hover {
	background: #555;
}

.modal_root {
	display: flex;
	/* align-items: center; */
	justify-content: center;
}

.modal_container {
	width: 90vw;
	border: 2px solid #000;
	box-shadow: 24;
	background: #30343C;
	color: #ffffff;
	border-radius: 5px;
	/* display: grid;
	grid-template-rows: 0.1fr auto; */
	height: fit-content;
	/* min-height: 500px; */
	/* height: fit-content; */
	outline: none;
	z-index: 1000000;
	position: relative;
	overflow-x: hidden;
	margin-top: 8vh;

}

.modal_body {
	overflow-y: scroll;
	height: fit-content;


	max-height: calc(100vh - 160px);
	overflow-x: hidden;
	transition: all 300ms ease-in;
}

.modal_body.move_task__container {
	height: 50vh !important;
}

/* width */
.modal_body::-webkit-scrollbar,
.modal_body::-moz-scrollbar {
	width: 2px;
}

/* Track */
.modal_body::-webkit-scrollbar-track,
.modal_body::-moz-scrollbar-track {
	background: transparent;
}

/* Handle */
.modal_body::-webkit-scrollbar-thumb,
.modal_body::-moz-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.modal_body::-webkit-scrollbar-thumb:hover,
.modal_body::-moz-scrollbar-thumb:hover {
	background: #555;
}

.modal_body,
.modal_header,
.modal_footer {
	padding: 0px;
	padding-top: 14px;
}

.modal_body,
.modal_header {
	border-bottom: 1px solid #000;
}

.modal_header {
	text-align: center;
	text-transform: capitalize;
}

.backdrop {
	z-index: 999 !important;
	color: #fff;
	position: fixed !important;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
}

.worksheet_body {
	display: flex;
	/* grid-template-columns: 0.4fr 1.5fr 0.5fr; */
	/* grid-template-columns: 250px auto; */
	width: 100%;
	min-height: 100vh;
	color: white;
}

.worksheet_left_sidebar {
	background: #111;
	/* padding: 0px 0px 30px 0px; */
	overflow: hidden;
	position: relative;
	height: 100vh;
	width: 22%;
	border-bottom: 0.75px solid #565656;
	display: flex;
	flex-direction: column;
}

.overlay_sidebar_notification {
	display: none;
	position: absolute;
	z-index: 100;
	top: 0;
	bottom: 0;
	left: 0;

	right: 0;
}

.worksheet_active {
	width: 100%;
	border-right: 0.75px solid #565656;
	/* padding: 30px 20px; */
	/* overflow: hidden; */
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
	padding: 0;
	margin: 0;
	height: 100vh;
}

.worksheet_content {
	height: 100vh;
	position: relative;
	/* overflow-x: hidden; */
	width: 84%;
	/* transition: 2s; */
}

.fa_check_icon {
	height: 18px;
	width: 18px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #5fb924;
	cursor: pointer;
	color: white;
}

.fa_check_icon_mobile {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #5fb924;
	cursor: pointer;
	color: white;
}

.fa_uncheck_icon {
	height: 18px;
	width: 18px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	cursor: pointer;
	border: 1px solid #878787;
}

.burger-icon {
	display: none;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
	/* display: none; */
}

.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
	display: none;
}

.group-input {
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 3px;
	border: none;
}

.group-elements {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.people-modal {
	padding: 14px 14px 15px 14px !important;
}

.people-modal-header {
	padding-top: 14px;
	padding-bottom: 14px;
}

@media only screen and (max-width: 767px) {
	.main-content {
		padding: 15px 0px !important;
		width: 100%;
	}

	.responsive-main {
		padding-top: 0px !important;
	}

	.on-mobile-padding {
		padding: 150px 0px !important;
	}

	.modal_body {
		padding: 0px;
	}

	.modal_container {
		width: 100vw;
		z-index: 9999;
		max-height: 100vh;
		margin-top: 0px;
	}

	.worksheet_content {
		width: 100%;
	}

	.full-screen-icon {
		display: none;
	}

	.worksheet_sidebar_section {
		display: none !important;
	}

	.overlay_sidebar_notification {
		display: block !important;
	}

	.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
		background-color: #181a1e;
		padding: 0 10px;
		display: block !important;
	}

	.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
		background-color: #181a1e;
		padding: 0 10px;
		width: 80%;
		display: block !important;
	}

	.worksheet_left_sidebar {
		display: none;
	}

	.burger-icon {
		display: block !important;
		font-size: 20px;
	}

	.modal_body {
		height: calc(100vh - 50px);
	}
}

.fa_cross_icon {
	height: 18px;
	width: 18px;
	float: right;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: red;
	cursor: pointer;
	color: white;
}

.close_as_unread {
	text-decoration: underline;
}

.close_as_unread:hover {
	color: rgb(72, 139, 247);
}

.focused {
	background-color: #2f2f2f !important;
}

.unselectable-text {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.responsive-comment-actions {
	margin-top: 22px;
}

.activity-button {
	cursor: pointer;
	background: transparent;
	color: white !important;
	border: 0;
	font-family: Poppins;
	opacity: 1;
	margin-bottom: 10px;


}

.activity-button:hover {
	text-decoration: underline;
}

@media (max-width: 575px) {
	.responsive-comment-actions {
		margin-left: -30px;
		margin-right: -30px;
	}
}

@media (max-width: 700px) {
	.responsive-comment-actions {
		padding: 14px;
	}

	.responsive-comment-add {
		padding: 14px;
		padding-bottom: 0px;
	}
}