.teamTableContainer {
	width: '100%';
	color: 'white';
	padding: '20px 30px';
	position: relative;
}

.addMember {
	position: absolute;
	top: 3rem;
	right: 3rem;
}
.addEditModal {
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
}

.form {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
}
.form > * {
	width: 100%;
	margin: 1% 0;
}
.form > * > * {
	width: 100%;
	/* margin: 0.25%; */
}

