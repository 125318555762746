.w-100 {
	width: 100%;
}

#accordion_container {
	transition: all 0.3s ease-out;
	background: transparent;
	color: white;
	box-shadow: none;
	margin: 0px;
	font-size: 14px;
}

#accordion_container .MuiAccordionDetails-root {
	display: block;
	padding: 0px;
}

#accordion_container .MuiAccordionSummary-root {
	padding: 0px 10px;
	min-height: 0px;
	box-shadow: none;
	color: white;
}

#accordion_container.MuiAccordion-root:before,
#accordion_container .MuiAccordion-root:before {
	height: 0px;
}

/* #accordion_container .MuiAccordionSummary-content.Mui-expanded{ */
/* margin: 10px 0px 0px;
    padding: 5px 0px; */
/* } */

.MuiSwitch-root {
	height: 27px;
}

#accordion_container .MuiAccordionSummary-content {
	height: fit-content;
	transition: 500px 1s ease-in-out;

	margin: 0px 0px;
}

.MuiCollapse-root .MuiCollapse-vertical .MuiCollapse-hidden {
	height: fit-content;
	visibility: visible;
}

.main-group-name {
	display: flex;
	padding: 10px 0px;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	color: #FFF;
	font-family: Poppins;
	font-size: 11px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 181.818% */
	text-transform: uppercase;
}
.group_name{
	color: #FFF;
}

.group_name :hover {
	color: white;
}

.box :hover {
	cursor: pointer;
}
.not-active-group{
	opacity: 0.5;
}

.height-fit {
	height: fit-content;
}

#accordion_container.MuiAccordion-root {
	transition: all 0.3s ease;
}

#accordion_container .MuiAccordionDetails-root {
	transition: all 0.3s ease;
	display: flex;
	padding-left: 21px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
}

.accordion-with-divider-container {
	animation: slideTop 300ms ease-in-out;
}

.group-name-container {
	display: flex;
	padding-left: 8px;
	align-items: center;
	flex: 1 0 0;
	align-self: stretch;
	justify-content: space-between;
}

/* .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
	transform: rotate(90deg) !important;
} */

@keyframes slideTop {
	from {
		opacity: 0;
		transform: translateY(40px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}