@font-face {
	font-family: 'Roboto';
	src: url(../../assets/fonts/RobotoMono.ttf) format('ttf');
}

@font-face {
	font-family: 'Digital Dismay';
	src: url(../../assets/fonts/DigitalDismay.ttf) format('ttf');
}

.listingsBody {
	overflow-y: scroll;
	overflow-x: hidden;

	height: 100vh;
	white-space: nowrap;
}

.navbarActive {
	width: calc(100% - 78px);
	background: rgb(25 26 31);
	opacity: 1;
	z-index: 9;
}

.navbarContent {
	min-height: 100px;
	background: rgb(25 26 31);
	opacity: 1;
	z-index: 9;
	padding: 30px 30px 20px;
	position: sticky;
	top: 0;
}

/* footer */
.listingsFooter {
	height: 60px;
	padding: 15px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	position: relative;
	color: white !important;
}

.progressbar {
	color: white !important;
}

.progressbarContainer {
	position: absolute;
	top: 0;
	left: 0;
	color: white !important;
	width: 100%;
	z-index: 10;
}

.footerEstimate {
	font-size: 12px !important;
}

.worksheetCountButton {
	font-size: 13px;
	background-color: transparent;
	border-radius: 4px;
	color: white;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	cursor: pointer;
	border: none;
}

.sheetName {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 21px;
	color: #ffffff;
}

.sheetCompletionPercentage {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	display: flex;
	align-items: flex-end;
	color: #fff;
}

.sheetCompletionRed {
	color: #ff0000;
}

.sheetCompletionGreen {
	color: #5fb924;
}

.sheetCompletionYellow {
	color: #fcba03;
}

.worksheetCard {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	/* margin: 10px 0px; */
	padding: 10px;
	transition: background-color 0.3s ease-in-out;
}

.sheetActive {
	color: #FFF;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	/* 161.538% */
}

.notActive {
	color: #FFF;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	opacity: 0.5;

}

.worksheetCard:hover {
	background: rgba(65, 65, 65, 0.35);
}

.worksheetCardActive {}

.customMenuContainer {
	display: block;
}

.taskParent:hover .customMenuContainer {
	display: block;
}

.taskCount {
	border-radius: 28px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	line-height: 15px;
	text-transform: capitalize;
	color: #ffffff;
	width: 30px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.taskCountWithoutEstimate {
	background: rgba(135, 135, 135, 0.75);
}

.taskCountHard {
	background: #ff0000;
}

.taskCountMedium {
	background: rgba(252, 186, 3, 0.75);
}

.taskCountEasy {
	background: rgba(95, 185, 36, 0.75);
}

.worksheetListContainer {
	border-top: 0.75px solid #565656;
	overflow-y: scroll;
}

.worksheetInstructionContainer {
	display: flex;
	padding: 8px;
	flex-direction: column;
	align-items: flex-start;
	gap: 1px;
	align-self: stretch;
}

.instructions {
	display: flex;
	padding: 8px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	width: 100%;
	color: #FFF;
	/* redwing.pages.dev/Poppins/Regular 13 */
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.instructions:hover {
	color: #fff;
	text-decoration: none;
}

.newWorkSheet {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.sidebarActionContainer {
	width: 100%;

}

.instructions:hover {
	background-color: rgba(65, 65, 65, 0.35);
}

/* .worksheetInstructionContainer:hover{
	background-color: rgba(65, 65, 65, 0.35);;
} */

.worksheetInstructionPara {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #878787;
	margin-top: 5px;
}

/* width */
.listingsBody::-webkit-scrollbar,
.leftSidebar::-webkit-scrollbar {
	width: 2px;
}

/* Track */
.listingsBody::-webkit-scrollbar-track,
.leftSidebar::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
.listingsBody::-webkit-scrollbar-thumb,
.leftSidebar::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.listingsBody::-webkit-scrollbar-thumb:hover,
.leftSidebar::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.listingsHeading {
	/* font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	padding: 0 15px;
	color: #fff; */
	/* display: flex; */
}

.openicon {
	cursor: pointer;
	border: 1px solid white;
	border-radius: 2px;

	margin-top: 5px;
}

.worksheetDrawerOpener {
	display: none;
}

.footerDrawerOpener {
	display: none;
}

.DrawerlistingsFooter {
	display: none;
}

.mainWorksheetContent {
	padding: 0px 30px 25px;
	width: 100%;
	overflow: auto;
}

.horizontalScroll {
	overflow-x: auto;
	white-space: nowrap;
	max-width: 100%;
}

.teamsNotification {
	width: 300px;
	min-height: 200px;
	height: 200px;
	top: calc(50vh - 100px);
}

.clearTeamsButton {
	cursor: pointer;
	background: gray;
	border-radius: 5px;
	padding: 5px;
	height: fit-content;
}

@media only screen and (max-width: 767px) {
	.openicon {
		display: none !important;
	}

	.navbarContent {
		width: 100%;
		min-height: fit-content;
		padding: 14px 14px 20px;
		position: fixed;
		top: 0;
		z-index: 10;
	}

	.listingsBody {
		height: calc(100dvh - 80px);
	}

	.mainWorksheetContent {
		margin-top: 0px;
		padding: 0 14px;
		overflow-x: auto;
	}

	.worksheetDrawerOpener {
		display: flex !important;
		flex-direction: column;
		gap: 5px;
		z-index: 10000;
		position: absolute;
		right: 0;
	}

	.worksheetDrawerOpener div {
		font-size: 10px;

		border: 1px solid rgba(255, 255, 255, 0.455);
		padding: 2px 10px;
		cursor: pointer;
		border-radius: 5px;
	}

	.DrawerlistingsFooter {
		display: block !important;
		padding: 15px 20px;
		font-size: 12px;
		position: relative;
		color: white !important;
	}

	.worksheetTitleContainer {
		flex: 0.7 !important;
	}

	.worksheetInstructionContainer {
		position: sticky;
		bottom: 40px;
		background: #111;
	}
}

.openfullicon {
	cursor: pointer;
}

.listingsHeading span {
	color: #a78bfa;
}

.hideChildsArrow {
	cursor: pointer;
}

.verticalLinesContainer {
	height: calc(100% - 33px);
	/* padding: 5px 0; */
	cursor: pointer;
	margin-top: 0px;
	width: 35px;
	margin-bottom: 4px;
}

.verticalLines {
	width: 1px;
	background-color: rgba(255, 255, 255, 0.2);
	height: 100%;
	cursor: pointer;
}

.verticalLines:hover {
	background-color: rgba(255, 255, 255, 0.5);
}

.animargin {
	display: flex;

	/* animation: anim 0.5s ease; */
}

.taskMainTree {

	padding-left: 10px;
	display: flex;
	align-items: center;
	gap: 4px;
	background: transparent;
	transition: background 300ms ease-in;

}

.taskMainTree:hover {
	border-radius: 4px;
	background: rgba(0, 0, 0, 0.50);
}

.parentTreeIcon {
	padding-left: 15px;
	padding-top: 7px;
	padding-bottom: 7px;
}

.parentTreeTask {
	padding-right: 10px;
	padding-block: 8.4px;

}

.erticleBorder {
	display: flex;

}

/* @keyframes anim {
	0%{
		margin-left: 1px;
	margin-left:8px;
	animation: anim 0.5s ease;
}
}
/* Chechking of task - tick */

.horizontalBar {
	width: 100%;
	position: relative;
}

.horizontalBar:after {
	content: '';
	position: absolute;
	height: 2px;
	top: 50%;
	left: 50%;
	width: 50%;
	transform: translate(-50%, -50%);
	background: #878787;

	border-radius: 2px;
}

.firework:after {
	content: '';
	position: absolute;
	height: 4px;
	top: 50%;
	left: 50%;
	width: 20%;
	transform: translate(-50%, -50%);
	animation: firework 0.5s ease forwards;
	border-radius: 5rem;
}

.horizontalBarClicked {
	width: 100%;
	position: relative;
}

.horizontalBarClicked:before {
	content: '';
	position: absolute;
	height: 3px;
	top: auto;
	left: 18%;
	width: 30%;
	transform: translate(-50%, -50%);
	background: rgb(255, 255, 255);
	transform: rotate(230deg);
	animation: goto30 0.5s linear forwards;
	border-radius: 0 2px 2px 0;
}

.horizontalBarClicked:after {
	content: '';
	position: absolute;
	height: 3px;
	top: auto;
	left: 33%;
	width: 0%;
	transform: translate(-50%, -50%);
	transform: rotate(-40deg);
	background: rgb(255, 255, 255);
	border-radius: 0 2px 2px 0;
	animation: goto60 0.5s linear forwards;
}

@keyframes goto60 {
	0% {
		width: 0%;
	}

	100% {
		width: 60%;
	}
}

@keyframes goto30 {
	0% {
		width: 0%;
		left: 50%;
	}

	100% {
		width: 30%;
	}
}

@keyframes firework {
	0% {
		opacity: 1;
		box-shadow: 0 0 0 -2px white, 0 0 0 -2px white, 0 0 0 -2px white, 0 0 0 -2px white,
			0 0 0 -2px white, 0 0 0 -2px white;
	}

	30% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		box-shadow: 0 -15px 0 0px white, 14px -8px 0 0px white, 14px 8px 0 0px white, 0 15px 0 0px white,
			-14px 8px 0 0px white, -14px -8px 0 0px white;
	}
}

.modal_container {
	position: absolute;
	top: 0%;
	left: 50%;
	margin-top: 50px;
	transform: translate(-50%, 0%);
	width: 800px;
	border: 2px solid #000;
	box-shadow: 24;
	background-color: #5a5a5a;
	color: #ffffff;
	border-radius: 5px;
	display: grid;
	grid-template-rows: 0.1fr auto;
	max-height: calc(100vh - 100px);
	min-height: 500px;
	height: fit-co;
	outline: none;
}

.scroll-hide {
	overflow-y: hidden;
}

.modal_body {
	overflow-y: scroll;
	height: 100%;
}

/* width */
.modal_body::-webkit-scrollbar {
	width: 2px;
}

/* Track */
.modal_body::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
.modal_body::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.modal_body::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.modal_body,
.modal_header,
.modal_footer {
	padding: 14px;
}

.modal_body,
.modal_header {
	border-bottom: 1px solid #000;
}

.modal_header {
	text-align: center;
	text-transform: capitalize;
}

.modal_user_list {
	display: grid;
	grid-template-columns: 0.1fr 0.7fr 0.2fr;
	margin: 10px 0;
}

.menu_container {
	display: none;
	margin-bottom: 0;
}

.rowContainer {
	padding: 2px 0;
	/* padding: 0; */
	margin-bottom: 0;
}

.childrenTaskContainer {

	display: flex;
	flex-direction: column;

}

.rowContainer:hover .menu_container:first {
	display: block;
}

.comment_container {
	background-color: #2f2f2f;
	border-radius: 10px;
	padding: 10px;
}

.comment_header {
	padding-bottom: 10px;
	border-bottom: 1px solid black;
	margin: auto 0;
	display: flex;
	align-items: 'center';
}

.comment_footer {
	padding-top: 10px;
	/* border-top: 1px solid black; */
	margin: auto 0;
	overflow: hidden;
}

.comment_body {
	padding-top: 10px;
	user-select: text;
}

.comment_body a {
	color: #fff !important;
	text-decoration: underline;
}

.task_description {
	word-wrap: break-word;
	user-select: text;
}

.worksheetTitleContainer {
	flex-grow: 1;
}

.anchorDescription {
	color: red !important;
}

.presetFooterComment {
	color: white;
	padding: 5px;
	height: auto;
	font-size: 0.875rem;
	opacity: 0.5;
	font-weight: 700;
	cursor: pointer;
	white-space: nowrap;
}

.presetFooterComment {
	color: #00bfff;
}

.presetFooterComment:hover {
	opacity: 1;
}

.commentChipsOnMobile {
	padding: 0;
}

.aiBtn {
	cursor: pointer;
	margin-left: 10px;
	background: transparent;
	border: none;
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	justify-content: center;
	font-size: 12px;

}

@media only screen and (max-width: 775px) {
	.responsiveText {
		font-size: 12px;
	}

	.presetFooterComment {
		font-size: 1.4rem;
	}

	.onMobileDeliveryLinks {
		justify-content: space-between;
		align-items: center;
		display: flex;
		width: 100%;
		margin-top: 10px;
	}

	.presetOnMobile {

		padding-left: 0px;
	}

	.commentChipsOnMobile {
		padding: 8px;
	}

	.DeliveryBtnOnMobile {
		padding: 8px;
	}

	.comment_container {
		width: 100%;
		border-radius: 0px;
		margin-bottom: 1px !important;
	}
}

@media only screen and (max-width: 576px) {
	.responsiveText {
		text-align: center;
		/* font-size: 8px; */
	}
}

@media only screen and (max-width: 466px) {
	.responsiveText {
		font-size: 11px;
	}
}

.taskActionFooter {
	height: 7vh;
	background: black;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	position: absolute;
	margin: auto;
	box-sizing: border-box;
	z-index: 2000000 !important;
	border: 1px solid #adabab;
	border-left: none;
	border-right: none;
}

.tdpHeaderBtn {
	background: transparent;
	border: none;
	cursor: pointer;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 5px;
}

/* @media only screen and (max-width: 390px) {
	.responsiveText{
		font-size: 10px;
	}
} */