.delivery-list{
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    
   
}
.delivery-list h2{
    color: #fff;
    padding-bottom: 50px;
    padding-left: 50px;
}
.link-container{
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px;
    color: #fff;
   
}
.backdrop{
    height: 100vh;
}