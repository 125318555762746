.activityCard{
    background-color: #2f2f2f;
    padding: 10px;
    border-radius: 10px;
    margin: 10px auto;
}

.activity{
    /* display: flex; */
    display: grid;
    grid-template-columns: 0.02fr 0.88fr 0.1fr;
}

.activity .icon{
    width: 50px;
}

.horizontal_list {
    display: flex;
    align-items: center;
}

.horizontal_list li {
    list-style: none;
    /* margin-right: 8px; */
}

.separator {
    margin: 0 8px;
    color: gray;
}

.icon_container{
    width: 40px;
    margin-right: 10px;
    position: relative;
}

.activity_type_icon{
    width: 20px;
    position: absolute;
    z-index: 22;
    top: -3.5px;
    right: -3.5px
}

.activity_avatar{ 
    width: 40px;
    height: 40px;
    font-size: 18px ;
    border-radius: 50%;
}

.openicon {
	cursor: pointer;
	border: 1px solid white;
	border-radius: 2px;
	margin-top: 5px;
	cursor: pointer;
}

.listingsHeading span {
	color: #a78bfa;
}