.MuiInput-underline:before,
.MuiInput-underline:after {
	border-bottom: none !important;
}

select:focus {
	outline: none;
}

.main-content .backdrop {
	height: 100%;
}

.internal-task-tag {
	background-color: #38b6f9;
	padding: 2px 4px;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	height: fit-content;
}

.text-editor-container {
	display: flex;
	width: 100%;
	padding: 16px 32px 32px 32px;
	flex-direction: column;

	gap: 10px;
	background: #24272D;
}

.task-lineage {
	display: flex;
	padding: 8px 0px;
	align-items: flex-start;
	align-self: stretch;
	color: var(--redwing-pages-dev-nero, #FFF);
	font-family: Poppins;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 16.5px;
	width: 100%;
	flex-direction: column;
	/* 150% */
}

.main-lineage {}



.task-main-info {
	display: flex;
	width: 100%;
	padding: 16px 32px 32px 32px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
}

.task-main-title {
	color: #ffff;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	/* 138.889% */
	letter-spacing: -0.18px;
}

.task-comments-actions {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
}

.task-comments-actions div {
	display: flex;
	width: 200px;
	padding: 16px 0px 8px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.user-comment-body .attachment--png,
.user-comment-body .attachment--jpg {
	display: flex;
	padding: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 5px;
	align-self: stretch;
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.user-comment-body .attachment--mp4 {
	padding: 16px;
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%);

}

.user-comment-body .attachment--png a,
.user-comment-body .attachment--jpg a {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.new-comment-animate {
	background: #30343C;
	-webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;

}

.button__primary {

	border-radius: 50px !important;
	background-color: #5FB924 !important;
	outline: 3px solid #60b92486 !important;
	padding: 6px 16px !important;

}


.button__primary .MuiButton-label {
	font-weight: 300;
	text-transform: capitalize;
	letter-spacing: 0px;

}

.comment_icons__parent div {
	transition: opacity 300ms ease-in;
}

.comment_icons__parent:hover div {
	opacity: 1 !important;
}

@-webkit-keyframes slide-in-blurred-top {
	0% {
		-webkit-transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
		transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0) scaleY(1) scaleX(1);
		transform: translateY(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slide-in-blurred-top {
	0% {
		-webkit-transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
		transform: translateY(-100px) scaleY(2.5) scaleX(0.2);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0) scaleY(1) scaleX(1);
		transform: translateY(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}

.attachment__caption {
	color: #FFF;
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	/* 190% */
	text-decoration-line: underline;
}

.task-meta-data {
	display: flex;
	align-items: flex-start;
	gap: 16px;

}

.user-comment-body {
	display: flex;
	padding: 0px 32px 32px 32px;
	flex-direction: column;
	gap: 8px;
	align-self: stretch;

}

.task-main-details {
	display: flex;
	padding-top: 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	align-self: stretch;
}

.user-comment-header {
	display: flex;
	padding: 32px 0px 16px 0px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
}

.comment-user {
	display: flex;
	max-width: 802.667px;
	padding-bottom: 0.25px;
	align-items: center;

}

.user-action-container {
	padding-right: 10px;
}

.comment-user-name {
	color: #FFF;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
}

.task-description-box {
	display: flex;
	padding-top: 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	width: 100%;
	/* overflow-x: auto; */
}

.comment-actions {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	align-self: stretch;
	flex-wrap: wrap;
}

.pre-comments-btn {
	display: flex;
	width: 200px;
	padding: 16px 0px 8px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.pre-btn-container {
	display: flex;
	align-items: center;
	gap: 8px;
}

.quick-reply {
	display: flex;
	padding: 4px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	border-radius: 8px;
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%);

}

.quick-reply-btn {
	display: flex;
	padding: 5px;
	align-items: flex-start;
	opacity: 0.5;
	cursor: pointer;
}

.quick-reply-text {
	color: #00BFFF;
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 12.5px;
	/* 125% */
}

.quick-reply-btn:hover {
	opacity: 1;
}

.pre-btn-label {
	display: flex;
	padding-right: 48px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.ai-btn {
	background: none;
	color: #fff;
	border: none;
	display: flex;
	gap: 5px;
	align-items: center;
	margin-left: 5px;
	cursor: pointer;
}

.comment-spinner {
	position: relative;
	width: 100%;
	height: 200px;
}

@media screen and (max-width:750px) {
	.text-editor-container {
		padding: 16px 10px 16px 10px;
	}

	.task-main-info {
		padding: 16px 10px 10px 10px;
		width: 100%;
	}

	.quick-reply {
		overflow-x: auto;
	}

	.quick-reply::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	.user-comment-body {
		padding: 0px 10px 32px 10px;
	}

	.comment-user-name {
		white-space: nowrap;

	}

	.task-meta-data {
		flex-wrap: wrap;
	}

	.responsive-copy-btn {
		flex-wrap: wrap;
	}

}