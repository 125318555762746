.videoPlayerContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000;
	position: relative;
}

.videoPlayerContainer video::-webkit-media-controls-fullscreen-button {
	display: none;
}

.playBtnContainer {
	width: 100%;
	background: rgba(0, 0, 0, 0.609);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	position: absolute;
	top: 0;
	bottom: 0;
}

.controlsBtnContainer {
	display: none;
	width: fit-content;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	gap: 16px;
	position: absolute;
	bottom: 34px;
	right: 130px;
}

.videoPlayerContainer:hover .controlsBtnContainer {
	display: flex;
}

@media screen and (max-width: 600px) {
	.controlsBtnContainer {
		right: 0;
		left: 0;
		bottom: 80px;
	}
}

.progressDisplay {
	width: 100%;
	background: rgba(0, 0, 0, 0.609);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	position: absolute;
	top: 0;
	bottom: 0;
}

.controlsBtnContainer button {
	background-color: transparent;
	color: #fff;
	border: 1px solid #fff;
	outline: 0;
	padding: 4px 8px;
	border-radius: 4px;
	font-size: 12px;
	cursor: pointer;
	transition: background-color 300ms ease-in-out;
}

.controlsBtnContainer button:hover {
	background-color: #c1c1c1;
	color: #000;
}

.controlsBtnContainer button.selectedRate {
	background-color: #fff;
	color: #000;
}

