.responsive2 {
    display: none;
}

.performanceTable {
    display: block;
     overflow-x: scroll;
      white-space: nowrap;
       width: 100%;
       height: 40vh;
       padding-bottom: 20px;
}
.performanceTable td,th {
    padding: 10px 30px 10px 5px;
}
.performanceTable th {
    text-align: center;
}


.rowColor {
    color: white;
}

.chart {
    width: 100%;
}
.weeklyChart .monthlyChart {
    width: 100%;
}

.text_white{
    color: 'white';
}

@media only screen and (max-width: 600px) {
    .responsive1 {
        display: none;
    }
    .responsive2 {
        display: block;
        padding: 0 10px;
    }

    .rowColor {
        color: #ABABAB;
    }

    .chart {
        overflow-y: scroll;
    }

    .weeklyChart {
        width: 1300px;
    } 

    .monthlyChart {
        width: 500px;
    }
}