.attachment--content {
	display: inline-block;
	vertical-align: middle;
}

.attachment.attachment--preview img:not(trix-editor .attachment.attachment--preview img) {
	width: auto;
	height: auto;
	max-width: 500px;
	max-height: 500px;
}

.wysiwyg--editor_primary_overlay {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #ffffffb8;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

trix-editor {
	border: none;
	width: 100%;
	padding: 16px;
	min-height: 8em;
}

.trix-container {
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.25);
	display: flex;
	width: 100%;
	/* padding: 8px; */
	flex-direction: column;
	align-items: flex-start;
	margin: auto;
}

trix-editor pre {
	white-space: pre-wrap;
	word-break: break-all;
	overflow-x: auto;
	width: 80%;
	margin: auto;
	margin-bottom: 10px;
	background: #383737;
	padding: 4px 6px;
	border-radius: 3px;
	display: block;
	color: #fff;
	margin-top: 10px;
	border-radius: 6px;
	overflow: auto;

}

trix-editor ul {
	list-style: disc;
}

trix-editor ul li pre,
trix-editor ol li pre {
	width: auto;
	margin: 0px;
	display: inline;
	white-space: pre-wrap;
	margin: 2px;
}



trix-toolbar .trix-button-group {
	border: none !important;
}

trix-toolbar .trix-button:not(:first-child) {
	border: none !important;
}

trix-toolbar .trix-button-group:not(:first-child) {
	margin-left: 0px !important;

}

trix-toolbar {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;
	position: sticky;
	z-index: 14;
	top: 0px;
	display: flex;
	height: 34.4px;
	padding-right: 0px;
	justify-content: center;
	align-items: center;
	/* border-radius: 8px;
	background: rgba(0, 0, 0, 0.25); */
	height: 34.4px;
	padding-right: 100px;
	align-items: flex-start;
	border-bottom: 1px solid #FFF;
	background: linear-gradient(90deg, rgb(29, 32, 37) 0%, rgba(29, 32, 37, 0.00) 100%);
	left: 0;
	border-top-left-radius: 8px;
	border-image-source: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 85%, rgba(255, 255, 255, 0) 100%);
	border-image-slice: 1;
}

trix-toolbar .trix-button-group {
	border: 1px solid #bbb;
	border-top-color: transparent;
	border-bottom-color: transparent;
	border-radius: 0px;
	margin-bottom: 0px;
}


trix-toolbar .trix-button {
	border: none;
	border-bottom: none;
}

trix-toolbar .trix-button-row {
	height: 100%;
	align-items: center;
}

trix-toolbar .trix-button-row .trix-button-group:nth-child(1) {
	height: 100%;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.10) !important;
	display: flex;
	padding: 0px 8px;
	justify-content: center;
	align-self: stretch;
	color: #fff;
}

trix-toolbar .trix-button-row .trix-button-group:nth-child(2) {
	height: 100%;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.10) !important;
	display: flex;
	padding: 0px 8px;
	justify-content: center;
	align-self: stretch;
}

trix-toolbar .trix-button-row .trix-button-group:nth-child(3) {
	height: 100%;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.10) !important;
	display: flex;
	padding: 0px 8px;
	justify-content: center;
	align-self: stretch;
}

trix-toolbar span {
	height: 100%;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.10) !important;
}

.trix-button-group .trix-button-group--text-tools {
	height: 100%;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.10) !important;

}

trix-editor .trix-button-group .trix-button-group--text-tools {
	display: flex;
	padding: 0px 8px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-right: 1px solid rgba(255, 255, 255, 0.10);
}


trix-toolbar .trix-button--icon-bold::before {
	background-image: url('../assets/icons/Bold.svg') !important;

}

trix-toolbar .trix-button--icon-italic::before {
	background-image: url('../assets/icons/italic.svg') !important;

}

trix-toolbar .trix-button--icon-strike::before {
	background-image: url('../assets/icons/strike.svg') !important;

}

trix-toolbar .trix-button--icon-link::before {
	background-image: url('../assets/icons/link.svg') !important;

}

trix-toolbar .trix-button--icon-heading-1::before {
	background-image: url('../assets/icons/title.svg') !important;
}

trix-toolbar .trix-button--icon-quote::before {
	background-image: url('../assets/icons/blockquote.svg') !important;

}

trix-toolbar .trix-button--icon-code::before {
	background-image: url('../assets/icons/code.svg') !important;

}

trix-toolbar .trix-button--icon-bullet-list::before {
	background-image: url('../assets/icons/bulletpoint.svg') !important;

}

trix-toolbar .trix-button--icon-number-list::before {
	background-image: url('../assets/icons/numberpoint.svg') !important;

}

trix-toolbar .trix-button--icon-decrease-nesting-level::before {
	background-image: url('../assets/icons/increasing.svg') !important;

}

trix-toolbar .trix-button--icon-increase-nesting-level::before {

	background-image: url('../assets/icons/decreasing.svg') !important;

}

trix-toolbar .trix-button--icon-attach::before {

	background-image: url('../assets/icons/attach.svg') !important;

}

trix-toolbar .trix-button--icon-undo::before {
	background-image: url('../assets/icons/undo.svg') !important;

}

trix-toolbar .trix-button--icon-redo::before {
	background-image: url('../assets/icons/redo.svg') !important;

}

trix-toolbar .trix-button.trix-active {
	background: none;
}

.pre-msg {
	margin-top: 16px;
}

.button__wysiwyg {
	border-radius: 50px !important;
	font-family: unset !important;

	padding: 6px 16px !important;
	font-weight: 400 !important;
	text-transform: capitalize !important;
	letter-spacing: 0px !important;
	font-size: 1.2rem !important;
}

.button__wysiwyg>.MuiLoadingButton-loadingIndicator {
	visibility: hidden !important;
	display: none !important;
}

.button__wysiwyg.Mui-disabled {
	outline-color: rgba(111, 111, 111, 0.25) !important;
	background-color: rgba(110, 110, 110, 1) !important;
	color: rgba(255, 255, 255, 0.521) !important;
}

.submit__wysiwyg {
	outline: 3px solid #1976d240 !important;
}

.cancel__wysiwyg {
	outline: 3px solid #d32f2f40 !important;
}

@media (max-width: 775px) {
	trix-toolbar {
		margin-left: -8px;
		margin-right: -8px;
		padding-left: 8px;
		padding-right: 8px;
	}

	.trix-container {
		overflow-x: auto;
	}

	.pre-message-text {
		word-break: break-word;
	}

	.pre-message-text div {
		word-break: break-word;
	}
}