.notification-action-button {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 12.5px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.10);
    border: none;
    cursor: pointer;
}
@media screen and (max-width:750px) {
    .notification-action-button{
        width: 35px;
        height: 35px;
    }
    
}