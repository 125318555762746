.main {
	padding-left: 75px;
	padding-bottom: 0;
}

.webBar {
	width: 75px;
	height: 100vh;
	background: #000000;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 5vh 0;
	position: fixed;
	left: 0;
	top: 0;
	box-sizing: border-box;
	z-index: 1000;
}

.icons1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5vh;
	color: white;


}

.icons2 {
	text-align: center;
	color: white;

}

.bsicon {
	width: 30px;
	font-size: 30px;
	color: white;
}

.bsiconmobile {
	width: 30px;
	font-size: 30px;
	color: white;
	margin: -4px;
}

.mobileBar {
	display: none;
}

@media screen and (max-width: 600px) {
	.main {
		padding-left: 0;
		padding-right: 0;
	}

	.mobileHidden {
		display: none;
	}

	.webBar {
		display: none;
	}

	.mobileBar {
		width: 100%;
		height: 70px;
		background: black;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 20px;
		position: fixed;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 0;
		box-sizing: border-box;
		z-index: 1000;
		padding-bottom: 8px;
	}

	.icons1,
	.icons2 {
		display: flex;
		flex-direction: row;
		gap: 1.5vh;
	}
}
