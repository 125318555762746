.projectCount{
    font-size: 20px;
    position: relative;
    display: inline-block;
    cursor: pointer;
}
  .ProjectCounttip{
      text-align: left;
      padding: 3px;
      padding-bottom: 10px;
      margin-left: 14px;
      position: absolute;
      width: 110px;
      left: -140px;
      visibility: hidden;
      font-size: 9px;
      background: transparent;
  }
  .ProjectCounttip div{
    line-height: 10px;
    margin-bottom: 8px;
  }
  .projectCount .ProjectCounttip::after {
    border-color:  black transparent transparent;
  }
  .projectCount:hover .ProjectCounttip {
    visibility: visible;
    background: none;
    background-image: linear-gradient(175.2deg, rgb(12, 10, 39) 0%, rgba(0, 0, 0, 0) 101.53%);
  }
  .projectCount:hover {
      text-decoration: underline;
  }

  .isSegmented{
    color: #fff;
    margin-top: 0px;
  }

  .select{
    /* color: #fff; */
    border: none;
    background-color: #fff;
  }

  .flexx{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-left: 5px;
  }

  .dft{
    font-weight: 600;
  }
  .unactive-segmenting {
    font-weight: 600;
    color: grey;
  }

  .isSegmented>p {
    cursor: pointer;
  }

  .weekPerformance{
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .weekPerformancetip{
      color: #fff;
      text-align: left;
      padding: 3px;
      padding-bottom: 10px;
      margin-left: 14px;
      position: absolute;
      width: 300px;
      left: -140px;
      display: none;
      font-size: 9px;
      z-index: 99;
      background-color: black;
  }
  .weekPerformancetip div{
    line-height: 10px;
    margin-bottom: 8px;
  }
  .weekPerformance .weekPerformancetip::after {
    border-color:  black transparent transparent;
  }
  .weekPerformance:hover .weekPerformancetip {
    display: block;
    background: black;
  }

  .weekPerformancetip:hover {
    display: block;
    background: black;
  }
  .weekPerformance:hover {
      text-decoration: underline;
  }

  #_msg b {
    color: white;
  }








    .absentCount {
      position: relative;
      cursor: pointer;
    }
  
    .AbsentCounttip {
      padding: 3px;
      position: absolute;
      left: 2vw;
      width: fit-content;
      visibility: hidden;
      font-size: 10px;
      background: transparent;
    }
  
    .absentCount .AbsentCounttip::after {
      border-color: black transparent transparent;
    }
  
    .absentCount:hover .AbsentCounttip {
      visibility: visible;
      background: none;
      background-image: linear-gradient(175.2deg, rgb(12, 10, 39) 0%, rgba(0, 0, 0, 0) 101.53%);
    }
  
    .absentCount:hover {
      text-decoration: underline;
    }




        .leavesCount {
          position: relative;
          cursor: pointer;
        }
    
        .LeavesCounttip {
          padding: 3px;
            position: absolute;
            top: -2vw;
            width: 100px;
            visibility: hidden;
            font-size: 10px;
            background: transparent;
            color: white;
            z-index: 1000;
        }
    
        .leavesCount .LeavesCounttip::after {
          border-color: black transparent transparent;
        }
    
        .leavesCount:hover .LeavesCounttip {
          visibility: visible;
          background: none;
          background-image: linear-gradient(175.2deg, rgb(12, 10, 39) 0%, rgba(0, 0, 0, 0) 101.53%);
        }
    
        .leavesCount:hover {
          text-decoration: underline;
        }



        .newTeamWorkPadding {
          width: 100%;
              position: relative;
              overflow-x: scroll;
              padding: 0 29px;
              padding-top: 32px;
              min-height: calc(100vh - 200px);
        }

                @media screen and (max-width: 600px) {
                  .newTeamWorkPadding {
                      width: 100%;
                        position: relative;
                        overflow-x: scroll;
                        padding: 0;
                        padding-top: 32px;
                    }
                }