.backdrop {
	z-index: 999;
	color: #fff;
	position: fixed;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
}

.notification {
	/*0.88fr 0.1fr*/
	width: 100%;
	word-wrap: break-word;

}

.notificationHeader {
	position: sticky;
	top: 0;
	padding: 30px 0 20px;
	background-color: #191a1f;
	z-index: 500;
}

.notificationCountContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.notificationSubheader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
}

.notificationHover {
	opacity: 1;
	scale: 1;
	rotate: z 0deg;

}

.anim {
	transition: all 800ms ease;
}

.fadOutBack {
	margin: 0;
	padding: 0;
	opacity: 0;
	height: 0 !important;
	scale: 0 !important;
	transform: translateZ(-100px);
}

/* .fadOutBack a {
	margin: 0;
	padding: 0;
	opacity: 0;
	height: 0;
} */
.marked_read__all {
	animation-name: fade-out-anim;
	animation-duration: 700ms;
	animation-timing-function: ease;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-delay: calc(150ms * var(--delay-multiplier));

}

@keyframes fade-out-anim {


	100% {
		/* margin: 0;
		padding: 0; */
		opacity: 0;
		height: 0 !important;
		scale: 0;
		transform: translateZ(-100px);
	}
}

@-webkit-keyframes fade-out-bck {
	0% {
		opacity: 1;
		transform: scale(1) translateZ(0);
	}

	100% {
		opacity: 0;
		transform: scale(0.5) translateZ(-100px);
	}
}

@keyframes fade-out-bck {
	0% {
		opacity: 1;
		transform: scale(1) translateZ(0);
	}

	100% {
		opacity: 0;
		transform: scale(0.5) translateZ(-100px);
	}
}

@media (max-width: 767px) {
	.notificationHeader {
		padding-left: 14px;
		padding-right: 14px;
		top: -15px;
	}
}

.drawerButtons {
	display: none;
}

.tdpHeaderBtn {
	background: transparent;
	border: none;
	cursor: pointer;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}

.mobView {
	width: 100% !important;
	position: relative !important;
	overflow-y: auto !important;
	height: 90vh !important;
}

.sidebarItem {
	width: 100%;
	display: flex;
	align-items: center;
	padding-top: 0px;
	padding-bottom: 0px;
	margin-bottom: 0;
	color: #ffffff99;
	text-decoration: none !important;
	cursor: pointer;
	transition: background-color 0.3s ease-in-out;
}

/* .sidebarItem:first-child {
	border-top: 0.5px solid #ffffff33;
} */

.sidebarItem:hover {
	color: #ffffff99;
	background: rgba(65, 65, 65, 0.35);
}

@media only screen and (max-width:1350px) {
	.sidebarScreen {
		width: 25%;
	}

}

@media only screen and (max-width:1085px) {
	.sidebarScreen {
		width: 35%;
	}

}

@media only screen and (max-width:824px) {
	.sidebarScreen {
		width: 48%;
	}

}

@media only screen and (max-width:770px) {
	.sidebarScreen {
		width: 51%;
	}

}

@media only screen and (max-width: 767px) {
	/* .notification > div:last-child {
        display: none!important;
    } */

	.drawerButtons {
		display: flex !important;
		gap: 5px;
		flex-direction: column;
		align-items: center;
	}

	.drawerButtons div {
		font-size: 12px !important;
		font-weight: 200 !important;
		border: 1px solid rgba(255, 255, 255, 0.474);
		padding: 2px 5px;
		border-radius: 5px;
	}

	.notification_title,
	.notification_comment_title {
		max-width: calc(100vw - 40px) !important;
	}

	.notification {
		padding-left: 14px;
		padding-right: 14px;
	}
}

.notification_title,
.notification_comment_title {
	white-space: normal;
	text-overflow: ellipsis;
	max-width: calc(80vw - 210px);
}

.notification:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.button {
	background: transparent;
	color: white;
	border: 1px solid white;
	padding: 1px 10px;
}

.horizontal_list {
	display: flex;
	align-items: center;
	margin-bottom: 0px;
}

.horizontal_list li {
	list-style: none;
	/* margin-right: 8px; */
}

.separator {
	margin: 0 8px;
	color: gray;
}

.icon_container {
	width: 40px;
	margin-right: 10px;
	position: relative;
}

.notification_type_icon {
	width: 20px;
	height: 20px;
	position: absolute;
	z-index: 22;
	top: -3.5px;
	right: -3.5px;
}

.savedNotificationList {
	width: 100%;
}

.savedNotificationList h5 {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 21px;
	color: #fff;
	margin: 0 !important;
	margin-top: 0 !important;
}

.saveListHeading {
	cursor: pointer;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-top: 10px;
}

.saveList {
	height: 90%;
	overflow-y: auto;
}

@media (max-width: 575px) {
	.notification_type_icon {
		width: 15px;
		height: 15px;
		position: absolute;
		z-index: 22;
		top: -3.5px;
		right: -6.5px;
	}
}

.notification_avatar {
	width: 40px;
	height: 40px;
	font-size: 18px;
	border-radius: 50%;
}

.all-caught-up-section {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	align-items: center;
	color: rgba(255, 255, 255, 0.53);
}

.fa_check_notification {
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: black;
	cursor: pointer;
	color: #5fb924;
}

.break-word {
	word-wrap: break-word;
}

.scrollableSidebar {
	overflow-y: auto;
}

.visibleOnMobile {
	display: none !important;
}

.NotificationsList {

	/* padding-left: 30px;
	padding-right: 30px; */
	/* padding-top: 20px; */
}

.notificationActionBtnContainer {
	display: flex;
	height: 40px;
	align-items: center;
	gap: 8px;
}

.notificationMainContainer {
	display: flex;
	padding: 32px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;

}



.notificationHover:hover {
	background: rgba(65, 65, 65, 0.35);
}

.notificationInfoContainer {
	display: flex;
	padding-bottom: 16px;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	width: 100%;
}

.notificationMainTitle {
	color: #FFF;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	width: 100%;
	/* 142.857% */
}

.notificationMetaData {
	display: flex;
	align-items: center;
	align-content: center;
	flex: 1 0 0;
	align-self: stretch;
	flex-wrap: wrap;
	color: rgba(255, 255, 255, 0.60);

	/* redwing.pages.dev/Poppins/Regular 10 */
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14.29px;
	white-space: nowrap;
	/* 142.9% */
}

.notificationMainItem {
	width: 100%;
	display: flex;
	gap: 10px;
}

.notificationMainComment {
	display: flex;
	padding-top: 5px;
	flex-direction: column;
	align-items: flex-start;
	color: #FFF;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
}

/* .notificationMainComment figure{
	text-decoration: underline;
	color: #fff;
	font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
} */
.notificationMainComment figure a {
	text-decoration: underline;
	color: #fff;
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;

}

.applyFadeBack {
	-webkit-animation: fade-out-bck 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-bck 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	;


}

@-webkit-keyframes fade-out-bck {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(-80px);
		transform: translateZ(-80px);
		opacity: 0;
	}
}

@keyframes fade-out-bck {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateZ(-80px);
		transform: translateZ(-80px);
		opacity: 0;
	}
}

@media (max-width: 575px) {
	.NotificationsList {
		padding-left: 0px;
		padding-right: 0px;
	}

	.notificationMainContainer {
		padding-left: 14px;
		padding-right: 14px;
	}

	.fa_check_notification {
		height: 20px;
	}

	.actionOnMobile {
		display: none !important;
	}
}